import Cookies from 'js-cookie'
// mainnet
import abi_ido_claim from "@/utils/abi/abi_claim_ido";
import abi_ido_seed from "@/utils/abi/abi_claim_seed";
import abi_ido_partnership from "@/utils/abi/abi_claim_partnership";
import abi_ido_private from "@/utils/abi/abi_claim_private";
import abi_cows from "@/utils/abi/abi_cows";

//testnest
import abi_claim_ido_testnet from "@/utils/abi_testnet/abi_claim_ido";
import abi_claim_seed_testnet from "@/utils/abi_testnet/abi_claim_seed";
import abi_claim_private_testnet from "@/utils/abi_testnet/abi_claim_private";
import abi_claim_partner_testnet from "@/utils/abi_testnet/abi_claim_partnership";
import abi_cows_testnet from "@/utils/abi_testnet/abi_cows";


export const TOKEN = 'token';
export const LANG = Cookies.get('lang');
export const WHITE_LIST_ROUTER = ['/']
export const API_PORT = 2053;
export const DOMAIN = process.env.NODE_ENV === 'production' ? 'claim.cowboysnake.com' : 'localhost';

export const DOMAIN_REF = process.env.NODE_ENV === 'production' ? `https://claim.cowboysnake.com` : `http://localhost`;

export const URL_HOST = `/${LANG}`
export const API_URL = process.env.NODE_ENV === 'production' ? `https://${DOMAIN}:${API_PORT}` : `http://${DOMAIN}:${API_PORT}`;

export const CHAIN_ID_HEX = process.env.NODE_ENV === 'production' ? '0x38' : '0x61'
export const RPC_URL = process.env.NODE_ENV === 'production' ? 'https://bsc-dataseed.binance.org' : 'https://data-seed-prebsc-1-s1.binance.org:8545'

export const CHAIN_ID = process.env.NODE_ENV === 'production' ? 56 : 97

export const ADDRESS_CONTRACT = process.env.NODE_ENV === 'production' ? '0x33b48893B8f119Fb45F431b36F830a9584804201' : '0xF5433CA87EbcEf5F013150291B92809C8f5Aa843'
// IDO OLD: 0xD048B000b19F2Ba75a582143c947ede3B0D76132
// IDO 2: 0xd8800F249FA5AF3E58A0DA7e97A54ee80056e799
// IDO 3: 0xe30157FDA82e42207F567435afD364626Af284c6
export const ADDRESS_CONTRACT_CLAIM_IDO = process.env.NODE_ENV === 'production' ? '0x4C3e93A646409F1d262CBeBf93Dc5D7e580591A5' : '0x58615B93739eE46927F10c363Bac8d42194B30d6'
export const ADDRESS_CONTRACT_CLAIM_SEED = process.env.NODE_ENV === 'production' ? '0x23dDAB0c064F6026719F69853d94b2C4cb3c9d69' : '0xfb22A36B379286C443c1031F426aC4CD6E8b2C37'
export const ADDRESS_CONTRACT_CLAIM_PRIVATE = process.env.NODE_ENV === 'production' ? '0x5137e02EAadec3511953ceC50b084062D98190Bb' : '0x3AfF2ecB28EcB573dF5F0090409b4923E92A2852'
export const ADDRESS_CONTRACT_CLAIM_PARTNERSHIP = process.env.NODE_ENV === 'production' ? '0xD87EF2c1a319C24e5c1e7B2a67706e8E09fec5f0' : '0xAC61Ef38f8e92b8C7203D95BA180aB5884dAA60d'

export const ABI_CLAIM_IDO = process.env.NODE_ENV === 'production' ? abi_ido_claim : abi_claim_ido_testnet
export const ABI_CLAIM_SEED = process.env.NODE_ENV === 'production' ? abi_ido_seed : abi_claim_seed_testnet
export const ABI_CLAIM_PRIVATE = process.env.NODE_ENV === 'production' ? abi_ido_private : abi_claim_private_testnet
export const ABI_CLAIM_PARTNERSHIP = process.env.NODE_ENV === 'production' ? abi_ido_partnership : abi_claim_partner_testnet
export const ABI_COWS = process.env.NODE_ENV === 'production' ? abi_cows : abi_cows_testnet

export const URL_LOGIN = `/auth`
export const URL_LOGOUT = `/auth`;
export const NO_REDIRECT = [URL_HOST, URL_LOGIN];
