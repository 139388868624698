import Vue from 'vue'
import Router from 'vue-router'
import Master from "@/views/_layouts/Master"
import Index from "@/views/Index"
import User from "@/views/User";
import Claim from "@/views/Claim";

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Master,
            children: [
                {
                    path: '/',
                    name: 'index-page',
                    component: Index
                },
                {
                    path: '/claim',
                    name: 'claim-page',
                    component: Claim
                },

            ]
        },
        // {
        //     path: '/list-user',
        //     name: 'user-page',
        //     component: User
        // }
    ]
})
