const getDefaultState = () => {
    return {
        detail: {
            id: null,
            wallet_address: null,
            ref_id: null,
            token: null,
        },
        wallet: {
            balance: 0,
            balance_COWS: 0,
            signer: null,
        }
    }
}

const state = {
    detail: {
        id: null,
        wallet_address: null,
        ref_id: null,
        token: null,
    },
    wallet: {
        balance: 0,
        balance_COWS: 0,
        signer: null,
    },
}

const mutations = {
    SET_BALANCE_TOKEN(state, data) {
        state.wallet.balance_COWS = data;
    },
    SET_PROFILE(state, data) {
        state.detail = data;
    },
    RESET_STATE(state) {
        localStorage.clear();
        Object.assign(state, getDefaultState())
    },
    UPDATE_WALLET_ADDRESS(state, data) {
        state.detail.wallet_address = data;
    },
    UPDATE_SIGNER(state, data) {
        state.wallet.signer = data;
    }
}

const actions = {
    updateWalletAddress({commit}, data) {
        commit('UPDATE_WALLET_ADDRESS', data)
    },
    updateSigner({commit}, data) {
        commit('UPDATE_SIGNER', data)
    },
    updateBalance({commit}, data) {
        commit('SET_BALANCE_TOKEN', data);
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
    login({commit}, data) {
        return new Promise((resolve, reject) => {
            API_NODE('POST', '/auth/login', data).then(response => {
                let {data, message, success, code} = response;
                if (code === 0 && success) {
                    commit('SET_PROFILE', data);
                    resolve(response);
                } else {
                    reject(message);
                }
            }).catch(reject);
        })
    },
    getListUser({commit}, data) {
        return new Promise((resolve, reject) => {
            API_NODE('POST', '/user/list', data).then(response => {
                let {data, message, success, code} = response;
                if (code === 0 && success) {
                    resolve(response);
                } else {
                    reject(message);
                }
            }).catch(reject);
        })
    },
    getIDbyWallet({commit}, data) {
        return new Promise((resolve, reject) => {
            API_NODE('POST', '/auth/get-ref-id', data).then(response => {
                let {data, message, success, code} = response;
                if (code === 0 && success) {
                    resolve(response);
                } else {
                    reject(message);
                }
            }).catch(reject);
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
