<template>
  <div class="header-bg-top">
    <div class="img-bottom"><img src="../theme/images/bg/bar.png" alt=""></div>
  </div>
</template>

<script>
import PopupConnect from "@/components/PopupConnect";
export default {
  name: "Header",
  components: {
    PopupConnect
  },
  data() {
    return {}
  },
  async mounted() {
    let vm = this;
    await vm.saveRef();
  },
  methods: {
    async saveRef() {
      let vm = this;
      await vm.$store.dispatch('account/getIDbyWallet', {
        wallet_address: vm.$route.query.ref_id,
      }).then(async response => {
        let {data, message, code, success} = response;
        if (success) {
          localStorage.setItem('ref_id', data)
        }
      }).catch(e => {
        vm.error(e.message)
      })
    },
  },
  watch: {}
}
</script>

<style scoped>

</style>
