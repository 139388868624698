<template>
  <footer class="">
    <div class="max-lg">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="footer-top">
              <div class="f-top-left">
                <div class="item"><a href="#">Privacy Policy </a></div>
                <div class="item"><a href="#">Terms of use</a></div>
              </div>
              <div class="f-top-right">
<!--                <a href="#pre_sale" class="link-icon-right">-->
<!--                  <div class="text">BUY DSE</div>-->
<!--                  <div class="icon"><img src="../theme/images/1-4.png" alt=""></div>-->
<!--                </a>-->
              </div>
            </div>
            <div class="footer-bottom">
              <div class="list-social-bottom">
                <a href="https://twitter.com/DeSpaceDSE" target="_blank">
                  <div class="icon-basic"><img src="../theme/images/2-1.png" alt=""></div>
                  <div class="icon-hover"><img src="../theme/images/2-1-2.png" alt=""></div>
                </a>
                <a href="https://t.me/DeSpaceDSE" target="_blank">
                  <div class="icon-basic"><img src="../theme/images/2-2.png" alt=""></div>
                  <div class="icon-hover"><img src="../theme/images/2-2-2.png" alt=""></div>
                </a>
<!--                <a href="#">-->
<!--                  <div class="icon-basic"><img src="../theme/images/2-3.png" alt=""></div>-->
<!--                  <div class="icon-hover"><img src="../theme/images/2-3-2.png" alt=""></div>-->
<!--                </a>-->
<!--                <a href="#">-->
<!--                  <div class="icon-basic"><img src="../theme/images/2-4.png" alt=""></div>-->
<!--                  <div class="icon-hover"><img src="../theme/images/2-4-2.png" alt=""></div>-->
<!--                </a>-->
<!--                <a href="#">-->
<!--                  <div class="icon-basic"><img src="../theme/images/2-5.png" alt=""></div>-->
<!--                  <div class="icon-hover"><img src="../theme/images/2-5-2.png" alt=""></div>-->
<!--                </a>-->
<!--                <a href="#">-->
<!--                  <div class="icon-basic"><img src="../theme/images/2-6.png" alt=""></div>-->
<!--                  <div class="icon-hover"><img src="../theme/images/2-6-2.png" alt=""></div>-->
<!--                </a>-->
              </div>
              <div class="block-copyright">
                Copyright © Despace
              </div>
              <div class="logo"><a href="#"><img src="../theme/images/logo.png" alt=""></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
