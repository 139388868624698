<template>
  <div class="block-bg-full">
    <div class="title-main text-center">
      <div class="position-relative d-inline-block text-uppercase">
        <div class="icon"><img src="../theme/images/logo/1-3.png" alt=""></div>
        <h1 class="text-gradient-yellow">COWS Distribution Portal</h1>
      </div>
    </div>
    <div class="bg-content">
      <div class="max-sm">
        <div class="block-text-code text-center">
          <div class="text-code">
            {{ account.detail.wallet_address }}
          </div>
          <div class="block-status">
            <a href="#" @click.prevent="disconnectWallet">Disconnect</a>
          </div>
        </div>
      </div>
      <div class="title-line text-center">
        <h3>Select your Vesting Round</h3>
      </div>
      <div class="block-select text-center">
        <div class="block-custom-select d-inline-block">
          <select v-model="selectRound" class="form-control text-gradient-yellow custom-select d-inline-block">
            <option value="IDO">ADVISER</option>
            <option value="SEED">SEED</option>
            <option value="PRIVATE">PRIVATE</option>
            <option value="PARTNERSHIP">PARTNERSHIP</option>
          </select>
          <div class="icon-select">
            <img src="../theme/images/icon/2-1.png" alt="">
          </div>
          <div class="bg-img"><img src="../theme/images/btn/3-1.png" alt="" style="height: 42px;"></div>
        </div>
      </div>
      <div class="max-sm">
        <div class="row text-center">
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="item-pre-sale">
              <div class="title">Available</div>
              <div class="value">{{ amount_claim_airdrop | money('',false,0) }} COWS</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="item-pre-sale">
              <div class="title">Claimed</div>
              <div class="value">{{ claimed_airdrop | money('',false,0) }} COWS</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="item-pre-sale">
              <div class="title">Claimable</div>
              <div class="value"> {{ amount_claim_airdrop | money('',false,0) }} COWS</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="#" class="btn-bg-img d-inline-block" @click.prevent="claimToken">
          <div class="text" style="color: #087e79;">CLAIMED</div>
          <div class="bg-img"><img src="../theme/images/btn/2-2.png" alt="" style="height: 42px;"></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {ethers} from "ethers";
import {
  ADDRESS_CONTRACT,
  ADDRESS_CONTRACT_CLAIM_IDO,
  ADDRESS_CONTRACT_CLAIM_SEED,
  ADDRESS_CONTRACT_CLAIM_PRIVATE,
  ADDRESS_CONTRACT_CLAIM_PARTNERSHIP
} from "@/utils/config";
import {ABI_CLAIM_IDO, ABI_CLAIM_SEED, ABI_CLAIM_PRIVATE, ABI_CLAIM_PARTNERSHIP, ABI_COWS} from "@/utils/config";

import {mapState} from "vuex";

export default {
  name: "Claim",
  computed: {
    ...mapState(['account', 'wallet_connect', 'contract']),
  },
  components: {
    Header,
    Footer
  },
  data() {
    return {
      selectRound: 'SEED',
      isWhiteList: false,
      amount_claim_airdrop: 0.00,
      claimed_airdrop: 0.00
    }
  },
  async created() {
    let vm = this
    if (vm.account.wallet.signer) {
      await vm.connectSmartContract();
      await vm.check_isWhiteList();
    }
  },
  methods: {
    async connectSmartContract() {
      let vm = this;
      let address = '';
      let abi = '';
      switch (vm.selectRound) {
        default: {
          abi = ABI_CLAIM_IDO
          address = ADDRESS_CONTRACT_CLAIM_IDO
          break
        }
        case 'SEED': {
          abi = ABI_CLAIM_SEED
          address = ADDRESS_CONTRACT_CLAIM_SEED
          break
        }
        case 'PRIVATE': {
          abi = ABI_CLAIM_PRIVATE
          address = ADDRESS_CONTRACT_CLAIM_PRIVATE
          break
        }
        case 'PARTNERSHIP': {
          abi = ABI_CLAIM_PARTNERSHIP
          address = ADDRESS_CONTRACT_CLAIM_PARTNERSHIP
          break
        }
      }
      if (vm.account.wallet.signer) {
        console.log(address);
        const contract = new ethers.Contract(address, abi, vm.account.wallet.signer);
        await vm.$store.dispatch('contract/setClaimContract', contract);
      }
    },
    async check_isWhiteList() {
      let vm = this;
      if (vm.contract.claim && vm.account.detail.wallet_address) {
        let {
          amount,
          amountClaimed,
          claimAt,
          isEnable
        } = await vm.contract.claim.userInfo(vm.account.detail.wallet_address);
        vm.isWhiteList = isEnable
        vm.claimed_airdrop = ethers.utils.formatEther(amountClaimed)
        vm.amount_claim_airdrop = vm.claimed_airdrop > 0 ? 0 : ethers.utils.formatEther(amount)
        vm.$forceUpdate();
      }
    },
    async claimToken() {
      let vm = this
      try {
        if (vm.contract.claim && vm.account.detail.wallet_address) {
          let {isEnable} = await vm.contract.claim.userInfo(vm.account.detail.wallet_address)
          if (isEnable) {
            await vm.contract.claim.claimCOW();
            await vm.check_isWhiteList();
            vm.success('Claim success');
            vm.$forceUpdate();
          } else {
            vm.error('Your wallet address is not white list')
          }
        } else {
          vm.error('You don\'t permit claim COWS token')
        }
      } catch (e) {
        vm.error(e.message);
      }
    },
    async disconnectWallet() {
      let vm = this;
      // Disconnect wallet connect
      if (vm.wallet_connect.provider) {
        await vm.wallet_connect.provider.disconnect();
      }
      await vm.$store.dispatch('account/resetState')
      vm.$forceUpdate();
      await vm.$router.push({name: 'index-page'})
      vm.error('Disconnect')

      // if metamask
    }
  },
  watch: {
    'account.wallet.signer'() {
      this.connectSmartContract();
    },
    'account.detail.wallet_address'() {
      this.check_isWhiteList();
    },
    'selectRound'() {
      this.connectSmartContract()
      this.check_isWhiteList();
    }
  }
}
</script>

<style scoped>

</style>
