<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'App',
  computed: {
    // ...mapState(['account']),
    currentRouteName() {
      return this.$route.name;
    }
  },
  async created() {
    let vm = this
  },
  mounted() {
    let vm = this
    vm.$nextTick(() => {
      $(".to-top").on("click", function (a) {
        a.preventDefault();
        $("html, body").animate({
          scrollTop: 0
        }, 800);
        return false;
      });
    })
  },
  methods: {},
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Cowboy Snake';
        // this.getBalance() // call again the method if the route changes
      }
    },
  }
}
</script>

<style>
#walletconnect-wrapper {
  color: #111;
}

.block-chart {
  overflow: auto;
}

#chartdiv {
  height: 300px;
  width: 700px;
}

#chartdiv g g[cursor="pointer"] {
  display: none !important;
}

/*//popup*/

.menusb a {
  color: #000 !important;
}

.menu-panel-root li.active a, .menu-panel-root li:hover a, .menu-panel-root li a:hover {
  color: #01854e !important;
}

.modal-main-banner .close {
  left: initial;
  right: 15px;
}

.modal-wallet .modal-title {
  font-size: 18px;
  font-weight: bold;
}

.modal-wallet .modal-content {
  border-radius: 20px;
}

.modal-wallet .modal-header {
  border: 0;
  padding: 20px 30px;
}

.modal-wallet .modal-body {
  padding: 0px 30px 20px;
}

.m-item-wallet {
  position: relative;
  color: #000000;
  min-height: 55px;
  border: 1px solid #5e64bb;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px 60px 10px 60px;
  font-size: 16px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  line-height: 30px;
  text-align: center;
}

.m-item-wallet .icon {
  position: absolute;
  content: "";
  top: 55%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px
}

.m-item-wallet .icon img {
  max-height: 50px;
}

.m-item-wallet .name-coin {
}

.m-item-wallet .status-link {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  right: 15px;
}

.m-item-wallet .status-link a {
  color: #000;
}

.m-item-wallet .status-link a.active {
  color: #055200;
}

.m-item-wallet .status-link a.disable {
  color: #ff0000;
}

.m-item-wallet:hover, .m-item-wallet.active {
  background: #65e2f0;
  color: #fff;
  font-weight: bold;
}

.modal-backdrop {
  z-index: 0 !important;
}

.menusb a{color: #000!important;}
.menu-panel-root li.active a, .menu-panel-root li:hover a, .menu-panel-root li a:hover{    color: #01854e!important;}

.modal-main-banner .close{left: initial; right: 15px;}
.modal-wallet .modal-title{font-size: 18px; font-weight: bold;}
.modal-wallet .modal-content{border-radius: 20px; background-color: #001715}
.modal-wallet .modal-header{border: 0; padding: 20px 30px;}
.modal-wallet .modal-body{padding: 0px 30px 20px;}

.m-item-wallet{position: relative; color: #ddca66; min-height: 55px; border: 1px solid #ddca66;margin-bottom: 10px; border-radius: 10px; padding: 10px 60px 10px 60px; font-size: 16px; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -ms-transition: all 0.3s linear; -o-transition: all 0.3s linear; transition: all 0.3s linear; line-height: 30px;text-align: center;}
.m-item-wallet .icon{position: absolute; content: ""; top: 55%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); right:10px }
.m-item-wallet .icon img{    max-height: 50px;}
.m-item-wallet .name-coin{}
.m-item-wallet .status-link{position: absolute; content: ""; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-size: 14px; right: 15px;}
.m-item-wallet .status-link a{    color: #000;}
.m-item-wallet .status-link a.active{    color: #055200;}
.m-item-wallet .status-link a.disable{color: #ff0000;}
.m-item-wallet:hover, .m-item-wallet.active{    background: #044542;color:#fff;font-weight: bold;}
.modal-wallet .modal-title{color: #ddca66;}
</style>
