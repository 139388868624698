import Vue from 'vue'
import * as toastr from 'toastr'
import 'toastr/toastr.scss'

Vue.mixin({
    created: function () {
        toastr.options = {
            "escapeHtml":false,
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "200",
            "hideDuration": "500",
            "timeOut": "1500",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
    },
    methods: {
        info(message) {
            toastr.info(message)
        },
        warning(message) {
            toastr.warning(message)
        },
        success(message) {
            toastr.success(message)
        },
        error(message) {
            toastr.error(message)
        },
    }
})
