import jQuery from "jquery";
import $ from "jquery";
$(document).ready(function () {
  $(".preloader").fadeOut();
  // menumain();
  /*--------- GO TO TOP ---------*/
  $(".to-top").on("click", function (a) {
    a.preventDefault();
    $("html, body").animate({
      scrollTop: 0
    }, 800);
    return false;
  });
  // scroll animation ------------------
  $(window).on("scroll", function (a) {
    if ($(this).scrollTop() > 150) {
      $(".to-top").fadeIn(500);
    } else {
      $(".to-top").fadeOut(500);
    }
  });


});
