<template>
  <div>
    <Loading/>
    <div class="wrapper-default single-page">
      <Header/>
      <div class="block-full">
        <div class="content-page">
          <Navbar/>
          <router-view/>
        </div>
      </div>
    </div>
    <a class="to-top" style="display: inline;">
      <img src="../../theme/images/gototop.png" alt="">
    </a>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
export default {
  name: "Master",

  components: {
    Loading,
    Header,
    Navbar
  },
}
</script>

<style scoped>

</style>
