<template>
  <div class="block-bg-full">
    <div class="title-main text-center">
      <div class="position-relative d-inline-block text-uppercase">
        <div class="icon"><img src="../theme/images/logo/1-3.png" alt=""></div>
        <h1 class="text-gradient-yellow">COWS Distribution Portal</h1>
      </div>
    </div>
    <div class="bg-content">
      <div class="title-line text-center">
        <h3>Claim Your Vesting</h3>
      </div>
      <div class="max-sm">
        <div class="row text-center">
          <div class="col-lg-12" style="font-size: 20px; margin-bottom: 30px;">
            Let us detect if you have any unclaimed COWS in seed <br>
            or private round by connecting to your wallet first.
          </div>
        </div>
      </div>
      <div class="link-bottom text-center" style="margin-bottom: 25px;">
        <a class="btn-bg-img" id="" data-toggle="modal" data-target="#airdrop-1-Modal">
          <div class="text text-gradient-yellow">CONNECT</div>
          <div class="bg-img"><img src="../theme/images/btn/1-3.png" alt="" style="width: 140px;"></div>
        </a>
      </div>
    </div>
    <PopupConnect v-bind:MetaMask="connectMM" v-bind:WalletConnect="walletConnect"/>
  </div>
</template>

<script>
import SmoothScroll from 'smooth-scroll'
import $ from "jquery";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {ADDRESS_CONTRACT, CHAIN_ID, CHAIN_ID_HEX, DOMAIN_REF, RPC_URL, TOKEN} from "@/utils/config";
import {mapState} from "vuex";

import PopupConnect from "@/components/PopupConnect";
import {ethers, providers} from "ethers";
import {ABI_COWS} from "@/utils/config";
import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
  name: "Index",
  components: {
    Header,
    Footer,
    PopupConnect
  },
  computed: {
    ...mapState(['account', 'wallet_connect', 'contract','metamask']),
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      checkAccount: '',
      isConnect: false,
    }
  },
  async created() {
    let vm = this;
  },
  async mounted() {
    let vm = this
    vm.$nextTick(async () => {
      let scroll = new SmoothScroll();
      $(window).on("scroll", function (a) {
        if ($(this).scrollTop() > 150) {
          $(".to-top").fadeIn(500);
        } else {
          $(".to-top").fadeOut(500);
        }
      });
      $(window).scroll(function () {
        var h_brow = $(window).height();

        if ($(window).width() > 200) {
          if ($(window).scrollTop() > 50) {
            $("#header-main-fixed").addClass("animated slideInDown menu-small");
          } else {
            $("#header-main-fixed").removeClass("animated slideInDown menu-small");
          }
        }
      });
    })
  },
  methods: {

    async loginAPI() {
      let vm = this
      await vm.$store.dispatch('account/login', {
        wallet_address: vm.account.detail.wallet_address,
        ref_id: localStorage.getItem('ref_id'),
      }).then(async response => {
        let {data, message, code, success} = response;
        if (code === 0 && success) { // is register
          localStorage.setItem(TOKEN, data.token);
          vm.ref_link = DOMAIN_REF + `?ref_id=${vm.account.detail.wallet_address}`
          vm.isConnect = true
          await vm.getBalance()
          $('#airdrop-1-Modal').modal('hide')
          await vm.$router.push({name: 'claim-page'})
          vm.success('Connect success')
        } else {
          vm.error(message)
        }
      }).catch(e => {
        vm.error(e.message)
      })
    },
    async connectMM() {
      let vm = this;
      let ethereum = window.ethereum;
      if (ethereum) {
        await ethereum.request({method: 'eth_requestAccounts'}).then(async () => {
          try {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{chainId: CHAIN_ID_HEX}],
            });
            let provider = new ethers.providers.Web3Provider(ethereum);
            await vm.$store.dispatch('metamask/setProvider', provider);
            await vm.$store.dispatch('account/updateSigner', await provider.getSigner());
            await vm.$store.dispatch('account/updateWalletAddress', await provider.getSigner().getAddress());
            await vm.loginAPI();
          } catch (error) {
            if (error.code === 4902) {
              try {
                await ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{chainId: CHAIN_ID_HEX, rpcUrl: RPC_URL,},],
                });
                await vm.connectMM();
              } catch (addError) {
                vm.error(addError)
              }
            } else if (e.code === 4001) {
              vm.error(`Can't connect wallet`);
            } else {
              vm.error(error)
            }
          }
        }).catch(e => {
          vm.error(e.message)
        })
      }
    },
    async changeAccount() {
      let vm = this;
      if (vm.isConnect) {
        window.ethereum.on('accountsChanged', async function (accounts) {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            vm.error('Please connect to MetaMask.');
            vm.isConnect = false
            await vm.$store.commit('account/RESET_STATE')

          } else if (accounts[0] !== vm.$store.state.account.detail.wallet_address) {
            vm.warning('Your address wallet is changed')
            vm.isConnect = false
            await vm.$store.commit('account/RESET_STATE')
          }
        });
        window.ethereum.on('chainChanged', async (chainId) => {
          if (chainId !== CHAIN_ID_HEX) {
            await vm.$store.commit('account/RESET_STATE')
            vm.isConnect = false
            vm.warning('Please change network to Binance Smart Chain!')
          }
        });
      }
    },
    async getBalance() {
      let vm = this;
      if (vm.account.wallet.signer) {
        await vm.$store.dispatch('contract/setToken', new ethers.Contract(ADDRESS_CONTRACT, ABI_COWS, vm.account.wallet.signer));
        let balance_COWS = await vm.contract.token.balanceOf(vm.account.detail.wallet_address);
        await vm.$store.dispatch('account/updateBalance', ethers.utils.formatEther(balance_COWS));
        vm.$forceUpdate();
      }
    },
    async walletConnect() {
      let vm = this
      try {
        const providerWalletConnect = new WalletConnectProvider({
          rpc: {56: RPC_URL, 97: RPC_URL},
          chainId: CHAIN_ID,
          network: "Binance",
          qrcode: true,
        });
        await providerWalletConnect.enable();
        await vm.$store.dispatch('wallet_connect/setProvider', providerWalletConnect);
        await vm.$store.dispatch('account/updateWalletAddress', providerWalletConnect.accounts[0]);

        providerWalletConnect.on("accountsChanged", async (accounts, chainId) => {
          await providerWalletConnect.disconnect();
          vm.$store.commit('account/RESET_STATE');
        });

        providerWalletConnect.on("disconnect", async (code, reason) => {
          await vm.$store.dispatch('contract/setClaimContract', null);
          await vm.$store.dispatch('contract/setToken', null);
          await vm.$store.dispatch('account/updateSigner', null);
          await vm.$store.dispatch('wallet_connect/setProvider', null);
          await vm.$store.dispatch('account/updateWalletAddress', null);
          await vm.$store.dispatch('account/resetState');
          vm.$forceUpdate();
        });

        providerWalletConnect.on("session_update", async (error, payload) => {
          if (error) {
            throw error;
          }
        });
        if(providerWalletConnect.chainId === CHAIN_ID){
          let providerEthers = new providers.Web3Provider(providerWalletConnect);
          let signer = await providerEthers.getSigner(vm.account.detail.wallet_address);
          await vm.$store.dispatch('account/updateSigner', signer);
          await vm.loginAPI();
        }else {
          await providerWalletConnect.disconnect();
          vm.$store.commit('account/RESET_STATE');
          vm.error('Please change network to Binance Smart Chain!')
        }

      } catch (e) {
        console.log(e.message);
        vm.error(e.message)
      }
    },
    async disconnectWallet() {
      let vm = this;
      // Disconnect wallet connect
      if (vm.wallet_connect.provider) {
        await vm.wallet_connect.provider.disconnect();
      }
      await vm.$store.dispatch('account/resetState')
      vm.$forceUpdate();
      vm.error('Disconnect')

      // if metamask
    }
  },
  watch: {

  }
}
</script>

<style scoped>

</style>
