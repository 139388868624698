import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import '@/theme/index'
// import '@/utils/wallet_connect'
import '@/mixins/notify'
// import '@/middlewares/permission'
import '@/utils/api'

import VueTree from '@ssthouse/vue-tree-chart'
Vue.component('vue-tree', VueTree)

Vue.prototype.jQuery = jQuery
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
