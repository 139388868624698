<template>
  <div class="content-page">
    <vue-tree
        style="width: 100%; height: 2000px; border: 1px solid gray;"
        :dataset="sampleData"
        :config="treeConfig"
    >
      <template v-slot:node="{ node, collapsed }">
        <span class="rich-media-node" :style="{ border: collapsed ? '2px solid grey' : '' }">
          <span class="user_id position-absolute"> {{ node.id }} </span>
          <img width="50"
               :src="`../theme/images/tokenomics/${(node.level > 6 || node.level <= 0) ? getRandomInt(1,6) : node.level}.png`"
               alt="">
            {{ splitAddress(node.wallet_address) }}
        </span>
      </template>
    </vue-tree>
  </div>
</template>

<script>

export default {
  name: "User",
  data() {
    return {
      sampleData: {
        id: 'root',
        children: []
      },
      treeConfig: {nodeWidth: 150, nodeHeight: 80, levelHeight: 150}
    }
  },
  async created() {
    let vm = this
    await vm.getListUser()
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    splitAddress(address) {
      let strTemp = '';
      return strTemp.concat(address.slice(0, 3), '...', address.slice(-4));
    },
    list_to_tree(list) {
      let map = {}, node, roots = [], i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        // console.log(map[list[i].id])
        list[i].children = []; // initialize the children
        list[i].img = Math.floor(Math.random() * (5 - 1) + 1)
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.ref_id != null) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.ref_id]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    async getListUser() {
      let vm = this
      await vm.$store.dispatch('account/getListUser', {}).then(async response => {
        let {data, message, code, success} = response;
        if (code === 0 && success) { // is register
          vm.sampleData = vm.list_to_tree(data)
          vm.success('Connect Successfully')
        } else {
          vm.error(message)
        }
      }).catch(e => {
        vm.error(e.message)
      })

    }
  }
}
</script>

<style scoped>
.rich-media-node {
  width: 100px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1da1f2;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  /*background: url(/static/img/bg.d9012de.png)no-repeat center center;*/
}
.rich-media-node:hover{
  background-color: #FF8D2D;
}
.user_id{
  background: #FF0A0A;
  opacity: 0.8;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 5px;
  font-size: 20px;
  top: -7px;
  color: #fff;
}
.content-page {
  /*background: #333;*/
  background: url(/static/img/bg.d9012de.png) repeat center center;
}
</style>
