import {API_URL, NO_REDIRECT, TOKEN, URL_LOGOUT} from "./config";
const axios = require('axios');

window.API_NODE = function (method = 'POST', url, data = {}, headers = {}) {
    switch (method.toUpperCase()) {
        default:
            return new Promise((resolve, reject) => {
                headers['Authorization'] = `Bearer ${localStorage.getItem(TOKEN)}`
                headers['Content-Type'] = `application/json`
                axios.post(`${API_URL}/api` + url, data, {
                    headers: headers,
                }).then(response => {
                    let data = response.data;
                    if (data.code === 4 && NO_REDIRECT.indexOf(window.location.pathname) < 0) {
                        // window.location.href = URL_LOGOUT;
                        resolve();
                    } else {
                        resolve(data);
                    }
                }).catch(reason => {
                    if (reason.response.status === 401) {
                        localStorage.setItem(TOKEN, "");
                    }
                    reject(reason.response.data)
                })
            })
        case "GET":
            return new Promise((resolve, reject) => {
                headers['Authorization'] = `Bearer ${localStorage.getItem(TOKEN)}`
                axios.get(url, {
                    params: data
                }, {
                    headers: headers,
                }).then(response => {
                    resolve(response.data);
                }).catch(reason => {
                    reject(reason.response.data)
                })
            })
    }

}
