import Vue from 'vue'
import * as currency from 'currency-formatter'

const moment = require('moment');

Vue.filter('money', (money, symbol = '', symbol_left = false, fixed = 2) => {
    if (symbol_left) {
        return currency.format(money, {
            symbol: symbol,
            decimal: '.',
            thousand: ',',
            precision: fixed,
            format: {
                pos: '%s %v',
                neg: '%s -%v',
                zero: '%s %v'
            },
        })
    }
    return currency.format(money, {
        symbol: symbol,
        decimal: '.',
        thousand: ',',
        precision: fixed,
        format: {
            pos: '%v %s',
            neg: '-%v %s',
            zero: '%v %s'
        },
    })
})

Vue.filter('is_active_class', (segment) => {
    return segment === location.pathname ? 'active' : '';
})

Vue.filter('convertDate', (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(date).utc().format(format)
})
Vue.filter('convertMicrosecond', (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(date, 'x').utc().format(format)
})

Vue.filter('convertTimeStamp', (timestamp, format = 'lll') => {
    return moment(timestamp, 'x').format(format);
})

Vue.filter('configSnake', (data) => {
    return data * 100;
})
