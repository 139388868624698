const state = {
    token: null,
    claim: null,
}

const mutations = {
    SET_TOKEN(state, data) {
        state.token = data;
    },
    SET_CLAIM_CONTRACT(state, data) {
        state.claim = data;
    }
}

const actions = {
    setToken({commit}, data) {
        commit('SET_TOKEN', data);
    },
    setClaimContract({commit}, data) {
        commit('SET_CLAIM_CONTRACT', data);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
