<template>
  <div class="block-nav-top">
    <div class="logo">
    <router-link :to="{name: 'index-page'}" tag="a">
      <img src="../theme/images/logo.png" alt="">
    </router-link>
    </div>
    <div class="block-account text-center" v-if="account.detail.wallet_address">
      <a href="#">
        <div class="text">
          <div class="text-code" >
            {{splitAddress(account.detail.wallet_address)}}
          </div>
        </div>
        <div class="bg-img"><img src="../theme/images/btn/1-2.png" alt="" style=" width: 175px; "></div>
      </a>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Navbar",
  computed: {
    ...mapState(['account']),
    currentRouteName() {
      return this.$route.name;
    }
  },
  async created() {
    let vm = this
  },
  methods: {
    splitAddress(address) {
      let strTemp = '';
      return strTemp.concat(address.slice(0, 4), '...', address.slice(-5));
    },
  }
}
</script>

<style scoped>

</style>
