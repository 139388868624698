<template>
  <!-- Modal -->
  <div class="modal modal-main-banner modal-wallet" id="airdrop-1-Modal" tabindex="-1"
       aria-labelledby="airdrop-1-ModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Connect to Wallet</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="m-item-wallet" @click.prevent="MetaMask">
            <div class="position-relative">
              <div class="icon">
                <img src="../theme/images/metamask.png">
              </div>
              <div class="name-coin">
                MetaMask
              </div>

            </div>
          </div>
          <div class="m-item-wallet" @click.prevent="WalletConnect">
            <div class="position-relative">
              <div class="icon">
                <img src="../theme/images/wallet_connect.png">
              </div>
              <div class="name-coin" >
                WalletConnect
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "PopupConnect",
  props: {
    MetaMask: Function,
    WalletConnect: Function,
  }
}
</script>

<style scoped>

</style>
